import { useCallback } from "react";
import sha256 from "crypto-js/sha256";

import { getTXOneAccountUrl, getSSOClientId, getSSORedirectUri, generateUUID } from "utils";
import { getLanguage } from "utils/lang";

const STATE_KEY = "mytxone:key";
const CODE_VERIFIER_KEY = "mytxone:cv";

export const useSSO = () => {
  // generates oauth login url to redirect to TXOne Account
  const getLoginUrl = useCallback(() => {
    const txoneAccountUrl = getTXOneAccountUrl();
    const locale = getLanguage();
    const resourceType = "code";
    const clientId = getSSOClientId();
    const redirectUri = getSSORedirectUri();
    const codeVerifier = generateUUID();
    const codeChallenge = btoa(sha256(codeVerifier)).replaceAll("=", "");
    const codeChallengeMethod = "S256";
    const approvalPrompt = "auto";
    const state = generateUUID();
    sessionStorage.setItem(STATE_KEY, state);
    sessionStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);
    return `${txoneAccountUrl}/api/oauth2/authorize?resource_type=${resourceType}&client_id=${clientId}&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}&state=${state}&approval_prompt=${approvalPrompt}&lang=${locale}`;
  }, []);

  // generates oauth register url to redirect to TXOne Account
  const getRegisterUrl = useCallback(() => {
    const txoneAccountUrl = getTXOneAccountUrl();
    const locale = getLanguage();
    const resourceType = "code";
    const clientId = getSSOClientId();
    const redirectUri = getSSORedirectUri();
    const codeVerifier = generateUUID();
    const codeChallenge = btoa(sha256(codeVerifier)).replaceAll("=", "");
    const codeChallengeMethod = "S256";
    const approvalPrompt = "auto";
    const state = generateUUID();
    sessionStorage.setItem(STATE_KEY, state);
    sessionStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);
    return `${txoneAccountUrl}/${locale}/signup?resource_type=${resourceType}&client_id=${clientId}&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}&state=${state}&approval_prompt=${approvalPrompt}`;
  }, []);

  return { getLoginUrl, getRegisterUrl };
};

export default {};
