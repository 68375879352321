import React from "react";
import { Box, Typography, Button, Stack, Divider, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { Chevron, YoutubeIcon, TwitterIcon, LinkedinIcon } from "components/Button";
import { ReactComponent as TXOneLogo } from "assets/images/logo_txone.svg";
import { useLoginStatusChecking, useSSO } from "hooks";
import { useLogin } from "./hooks";

export function LandingPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { loading } = useLogin();
  const { getLoginUrl, getRegisterUrl } = useSSO();
  useLoginStatusChecking();

  const handleLoginClick = () => {
    const url = getLoginUrl();
    window.location = url;
  };

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "space-between",
          justifyItems: "center",
          alignItems: "start",
          flexDirection: "column",
          backgroundImage: "url('/assets/images/bg.svg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
          }}
        >
          <Box
            sx={{
              width: 146,
              height: 37,
              marginTop: "40px",
              marginLeft: "40px",
            }}
          >
            <TXOneLogo />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: 0,
            [theme.breakpoints.down("md")]: {
              marginLeft: theme.spacing(5),
              marginRight: theme.spacing(5),
              width: "auto",
            },
            [theme.breakpoints.up("md")]: {
              marginLeft: theme.spacing(12),
              marginRight: 0,
              width: "60%",
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#FFFFFF",
              font: "normal normal bold 50px/57px Ubuntu",
              [theme.breakpoints.down("md")]: {
                marginTop: theme.spacing(10),
                marginBottom: theme.spacing(5),
              },
              [theme.breakpoints.up("md")]: {
                marginBottom: theme.spacing(5),
              },
              [theme.breakpoints.up("xl")]: {
                marginBottom: theme.spacing(10),
              },
              overflowWrap: "break-word",
            }}
          >
            MyTXOne Portal
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#FFFFFF",
              font: "normal normal normal 24px/34px Ubuntu",
              overflowWrap: "break-word",
            }}
          >
            {t("pages.landing.siteDescription")}
          </Typography>

          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                width: "auto",
              },
              [theme.breakpoints.up("md")]: {
                width: "450px",
              },
            }}
          >
            <Stack
              sx={{
                [theme.breakpoints.down("xl")]: {
                  marginTop: theme.spacing(6),
                },
                [theme.breakpoints.up("xl")]: {
                  marginTop: theme.spacing(8),
                },
              }}
              alignItems="center"
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2 }}
            >
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  pt: theme.spacing(2),
                  pb: theme.spacing(2),
                  width: 200,
                }}
                endIcon={<Chevron />}
                onClick={handleLoginClick} // Update this line
              >
                {t("pages.landing.button.login")}
              </Button>
              <Box
                component="span"
                sx={{
                  font: "normal normal normal 16px/18px Ubuntu",
                  color: "#FFFFFF",
                }}
              >
                {t("pages.landing.or")}
              </Box>
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #FFFFFF",
                  color: "#FFFFFF",
                  pt: theme.spacing(2),
                  pb: theme.spacing(2),
                  width: 200,
                  "&:hover": {
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    backgroundColor: "hsla(0,0%,100%,.078)",
                  },
                }}
                endIcon={<Chevron />}
                onClick={() => {
                  const url = getRegisterUrl();
                  window.location = url;
                }}
              >
                {t("pages.landing.button.register")}
              </Button>
            </Stack>
            <Box
              sx={{
                [theme.breakpoints.down("xl")]: {
                  marginTop: theme.spacing(8),
                },
                [theme.breakpoints.up("xl")]: {
                  marginTop: theme.spacing(9),
                },
              }}
            >
              <Divider sx={{ backgroundColor: "#574F64", opacity: 1, width: "auto" }} flexItem />
              <Stack direction="row" spacing={1} sx={{ marginTop: theme.spacing(1) }}>
                <YoutubeIcon />
                <TwitterIcon />
                <LinkedinIcon />
              </Stack>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#FFFFFF",
                  opacity: 0.5,
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(3),
                }}
              >
                © {new Date().getFullYear()} TXOne Networks. {t("common.claimOfRight")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            backgroundColor: "rgba(163, 162, 162, 0.5)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" size={60} />
        </Box>
      ) : null}
    </>
  );
}
export default LandingPage;
